<template>
  <div>
    <el-dialog
      title="修改"
      center
      destroy-on-close
      :modal="false"
      :visible.sync="showedit"
      :close-on-click-modal="false"
      width="80%"
      top="5vh"
    >
      <div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="活动名称:" prop="name">
            <el-input v-model="form.name" placeholder="活动名称"></el-input>
          </el-form-item>
          <el-form-item label="活动时间:" prop="time">
            <el-date-picker
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动说明:" prop="desc">
            <el-input
              v-model="form.desc"
              type="textarea"
              :rows="4"
              placeholder="请输入活动说明"
            ></el-input>
          </el-form-item>
          <el-form-item label="限定业务员:" prop="limit_users">
            <!--          .split(',').length-->
            {{ form.limit_users | limit }}
            <add-employee
              ref="yee"
              :imlit="form.limit_users"
              @set-userid="setUserid"
            ></add-employee>
          </el-form-item>
          <el-form-item label="活动方案:" prop="sche_list">
            <el-table stripe :data="form.sche_list">
              <el-table-column
                label="方案"
                type="index"
                align="center"
              ></el-table-column>
              <el-table-column
                label="预存金额"
                align="center"
                prop="total_amount"
              >
                <template #default="{ row }">
                  <el-input v-model="row.total_amount"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="赠送金额"
                align="center"
                prop="gift_amount"
              >
                <template #default="{ row }">
                  <el-input v-model="row.gift_amount"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="协议赠品" align="center">
                <template #default="{ row, $index }">
                  <span
                    style="cursor: pointer; text-decoration: underline"
                    @click="handlergive(row, $index)"
                  >
                    {{ row.give_data.length }}
                  </span>
                  <giveaway-ding
                    v-if="showedit"
                    :index="$index"
                    :goods-list="form.sche_list[$index].give_data"
                    @xieyi="xieyi"
                  ></giveaway-ding>
                </template>
              </el-table-column>
              <el-table-column label="限定商品" align="center">
                <template #default="{ row, $index }">
                  <div @click="handlergoods(row, $index)">
                    <div>{{ row.goods_data.length }}</div>
                    <!-- <i class="el-icon-edit-outline"></i> -->
                    <limited
                      v-if="showedit"
                      ref="limited"
                      :goods-list="form.sche_list[$index].goods_data"
                      :brand-list="form.sche_list[$index].brand_data"
                      :index="$index"
                      @xianding="xianding"
                    ></limited>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template #default="{ row, $index }">
                  <el-button type="text" @click="handleAdd(row, $index)">
                    添加
                  </el-button>
                  <el-button type="text" @click="handleradding(row, $index)">
                    复制
                  </el-button>
                  <el-button type="text" @click="handlerrvm($index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span="24">
                <el-switch
                  v-model="form.repeat_status"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="活动可重复参加"
                ></el-switch>
                <br />
                <el-switch
                  v-model="form.price_status"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="下单时业务员可修改价格"
                ></el-switch>

                <br />
                <el-switch
                  v-model="form.exceed_status"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="允许超额下单:勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额"
                ></el-switch>

                <br />
                <el-switch
                  v-model="form.gift_status"
                  active-text="允许使用预存款购买常规赠品"
                  :active-value="'1'"
                  :inactive-value="'0'"
                ></el-switch>

                <br />
                <el-radio v-model="form.check_status" label="1">
                  必须审核:所有预存款协议，必须后台审核后方可生效
                </el-radio>
                <el-radio v-model="form.check_status" label="0">
                  异常审核:勾选后，业务员修改协议内容，需后台审核后方可生效
                </el-radio>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="赠送条件:">
            <el-row>
              <span></span>
              <el-radio-group v-model="form.gift_goods_type">
                <el-radio
                  v-for="city in citiesing"
                  :key="city.id"
                  :label="city.id"
                >
                  {{ city.name }}
                </el-radio>
              </el-radio-group>
            </el-row>
          </el-form-item>
        </el-form>
        <!--   协议赠品
        <el-dialog
          width="60%"
          title="协议赠品"
          :visible.sync="showgive"
          append-to-body
        >
          <div>
            <el-table stripe :data="form.sche_list[giveindex].give_data">
              <el-table-column
                prop=""
                label="商品名称"
                width="auto"
                align="center"
              >
                <template #default="{ row }">
                  <template>
                    <goods-search
                      :f-key="row.goods_name"
                      :search="false"
                      @select-goods-all="selectGoods($event, row)"
                    ></goods-search>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_specs"
                label="规格"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="unit_id"
                label="单位"
                width="auto"
                align="center"
              >
                <template #default="{ $index, row }">
                  <template>
                    <el-select
                      v-model="row.unit_id"
                      placeholder="选择单位"
                      style="width: 70px"
                      @change="unitChange($index, $event, row)"
                    >
                      <el-option
                        v-for="item in row.arr_unit"
                        :key="item.id"
                        :label="item.unit_name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_num"
                label="数量"
                width="auto"
                align="center"
              >
                <template #default="{ row }">
                  <template>
                    <el-input v-model="row.goods_num" style="width: 80px" />
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作" width="auto" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="copy(scope.$index, scope.row)">
                    复制
                  </el-button>
                  <el-button
                    type="text"
                    @click="deleter(scope.$index, scope.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog> -->
        <!--     限定商品
        <el-dialog
          width="60%"
          title="限定商品"
          :visible.sync="showspecs"
          append-to-body
          :close-on-click-modal="false"
          destroy-on-close
          :modal="false"
        >
          <div>
            <el-row type="flex" class="row-bg" justify="space-between">
              <span>限定品牌:</span>
              <edit-brand ref="tree" @treelit="treelit"></edit-brand>
            </el-row>
            <el-row :gutter="20" style="padding: 10px 0px">
              <el-col
                v-for="(item, indexd) in form.sche_list[goodsindex].brand_data"
                :key="indexd"
                :span="6"
              >
                <el-button class="el-icon-close" @click="deletetree(indexd)">
                  {{ item.brand_name }}
                </el-button>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <span>限定商品:</span>
              <edit-goods ref="goods" @addgoos="addgoos"></edit-goods>
            </el-row>
            <el-row>
              <el-table
          stripe
                :data="form.sche_list[goodsindex].goods_data"
                max-height="300"
              >
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                  v-for="(list, indexs) in comle"
                  :key="indexs"
                  :prop="list.prop"
                  :label="list.label"
                  :align="list.align"
                  width=""
                >
                  <template v-if="list.label == '下单价'" #default="{ row }">
                    <el-input v-model="row.goods_price"></el-input>
                  </template>
                  <template v-else #default="{ row }">
                    {{ row[list.prop] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  prop=""
                  align="center"
                  width="auto"
                >
                  <template #default="{ $index, row }">
                    <el-button type="text" @click="deletetab($index, row)">
                      删 除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </el-dialog>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = !showedit">取消</el-button>
        <el-button type="primary" @click="handlersubmit">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import AddEmployee from './AddEmployee.vue'
  import { postAction } from '@/api/Employee'
  import GiveawayDing from './GiveawayDing.vue'
  import limited from './limited'

  export default {
    name: 'Edit',
    filters: {
      limit(val) {
        console.log(val, '人员')
        if (val != '') {
          return val.split(',').length
        } else {
          return 0
        }
      },
    },
    components: {
      AddEmployee,
      GiveawayDing,
      limited,
    },
    data() {
      return {
        showedit: false,
        showgive: false,
        showspecs: false,
        giveindex: 0,
        goodsindex: 0,
        form: {
          time: [],
          id: '',
          name: '',
          desc: '',
          join_start_at: '',
          join_end_at: '',
          repeat_status: '0',
          exceed_status: '0',
          gift_goods_type: '1',
          check_status: '0',
          price_status: '0',
          limit_users: '',
          gift_status: '0',
          sche_list: [
            {
              total_amount: '',
              gift_amount: '',
              give_data: [
                {
                  goods_id: '',
                  goods_name: '',
                  goods_specs: '',
                  brand_name: '',
                  brand_id: '',
                  unit_id: '',
                  unit_name: '',
                  goods_num: '',
                },
              ],
              brand_data: [
                {
                  brand_id: '',
                  brand_name: '',
                },
              ],
              goods_data: [
                {
                  goods_id: '',
                  goods_name: '',
                  goods_specs: '',
                  brand_name: '',
                  brand_id: '',
                  unit_id: '',
                  unit_name: '',
                  goods_price: '',
                },
              ],
            },
          ],
        },
        citiesing: [
          { name: '签约即送', id: '1' },
          { name: '全额收款后赠送', id: '2' },
          { name: '消费完后赠品', id: '3' },
        ],
        url: {
          info: '/promoteAdmin/deposit/detail',
          save: '/promoteAdmin/deposit/update',
        },
        comle: [
          {
            prop: 'goods_name',
            label: '商品名称',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_specs',
            label: '规格',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'unit_name',
            label: '单位',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_price',
            label: '下单价',
            align: 'center',
            width: 'auto',
          },
        ],
      }
    },
    watch: {
      'form.time'(val) {
        try {
          if (val.length > 0) {
            try {
              this.form.join_start_at = val[0]
              this.form.join_end_at = val[1]
            } catch (e) {
              console.error(e)
            }
          } else if (val == null || val == 'undefined') {
            this.form.join_start_at = ''
            this.form.join_end_at = ''
          }
        } catch (e) {
          this.form.join_start_at = ''
          this.form.join_end_at = ''
        }
      },
      showgive(val) {
        if (!val) {
          let gives = this.form.sche_list[this.giveindex].give_data.filter(
            (list) => {
              console.log(list)
              return list.goods_id != ''
            }
          )
          this.form.sche_list[this.giveindex].give_data = gives
          console.log(gives, '这个是啥')
        }
      },
      showedit(val) {
        if (!val) {
          this.form.time = []
        }
      },
    },
    methods: {
      deletetree(index) {
        this.form.sche_list[this.goodsindex].brand_data.splice(index, 1)
      },
      handlerinfo(id) {
        postAction(this.url.info, { id: id })
          .then((res) => {
            Object.assign(this.form, res.data)
            console.log(res, this.form)
            this.form.time.push(res.data.join_start_at)
            this.form.time.push(res.data.join_end_at)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleradding(row, index) {
        // this.form.sche_list.splice(index, 0, JSON.parse(JSON.stringify(row)))
        let d = JSON.parse(JSON.stringify(row))
        d.id = 0
        this.form.sche_list.splice(index + 1, 0, JSON.parse(JSON.stringify(d)))
      },
      handlerrvm(index) {
        if (this.form.sche_list.length > 1) {
          this.form.sche_list.splice(index, 1)
        } else {
          this.$message({
            type: 'warning',
            message: '最后一个不支持删除',
          })
        }
      },
      handlergoods(row, index) {
        this.goodsindex = index
        this.showspecs = true
      },
      xieyi(list, index) {
        console.log(list, index)
        try {
          var d = []
          list.forEach((itme) => {
            let z = {
              goods_id: itme.goods_id,
              goods_name: itme.goods_name,
              goods_num: itme.goods_num,
              goods_specs: itme.specs,
              brand_name: itme.brand_name,
              brand_id: itme.brand_id,
              unit_id: itme.unit_id,
              unit_name: itme.unit_name,
            }
            d.push(z)
          })
          this.form.sche_list[index].give_data = d
        } catch (error) {
          console.log(error, '异常捕获')
        }
      },
      handlergive(row, index) {},
      // handlergive(row, index) {
      //   this.giveindex = index
      //   this.showgive = true
      //   if (this.form.sche_list[this.giveindex].give_data.length > 0) {
      //   } else {
      //     let listgive = {
      //       goods_id: '',
      //       goods_name: '',
      //       goods_specs: '',
      //       brand_name: '',
      //       brand_id: '',
      //       unit_id: '',
      //       unit_name: '',
      //       goods_num: '',
      //     }
      //     this.form.sche_list[this.giveindex].give_data.push(listgive)
      //   }
      // },
      /*
       * 协议赠品下拉框
       * */
      selectGoods(val, row) {
        console.log('val', val, row)

        try {
          let listgoods = {
            goods_id: val.goods_id,
            goods_name: val.goods_name,
            goods_specs: val.specs,
            brand_name: val.brand_name,
            brand_id: val.brand_id,
            unit_id: val.unit_id,
            unit_name: val.unit_name,
            goods_num: val.quantity,
            arr_unit: val.arr_unit,
          }
          Object.assign(row, listgoods)
        } catch (e) {
          console.error(e)
        }
      },
      // 表格内单位切换
      unitChange(index, e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
        let unitname = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        console.log(unitname, '单位')
        row.unit_name = unitname
      },
      copy(index, row) {
        console.log(index, row, '什么')
        let rowS = JSON.parse(JSON.stringify(row))
        // let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.form.sche_list[this.giveindex].give_data.splice(index, 0, rowS)
      },
      deleter(index, row) {
        this.form.sche_list[this.giveindex].give_data.splice(index, 1)
      },
      /*
       * 限定商品方法
       * hhy
       * */
      treelit(res) {
        let brand = res.map((list) => {
          return {
            brand_id: list.id,
            brand_name: list.brand_name,
          }
        })

        this.form.sche_list[this.goodsindex].brand_data = brand
      },
      /*
       * 添加限定商品
       * */
      addgoos(res) {
        console.log(res, '选后的商品')
        var setgoods = [...this.form.sche_list[this.goodsindex].goods_data]
        console.log(setgoods.length)
        for (var j = 0; j < setgoods.length; j++) {
          console.log(setgoods[j], '222222222')
          var list = setgoods[j]
          for (var i = 0; i < res.length; i++) {
            console.log(res[i], '33333333')
            if (list.goods_id === res[i].goods_id) {
              console.log(list.goods_id)
              res.splice(i, 1)
              break
            }
          }
        }
        let listres = res.map((list) => {
          return {
            goods_id: list.goods_id,
            goods_name: list.goods_name,
            goods_specs: list.specs,
            brand_name: list.brand_name,
            brand_id: list.brand_id,
            unit_id: list.unit_id,
            unit_name: list.goods_unit,
            goods_price: list.sell_price,
          }
        })
        this.form.sche_list[this.goodsindex].goods_data.push(...listres)
      },
      deletetab(index, row) {
        this.form.sche_list[this.goodsindex].goods_data.splice(index, 1)
      },
      setUserid(userid) {
        console.log(userid)
        this.form.limit_users = userid.join(',')
      },
      handlersubmit() {
        if (this.form.sche_list.length < 1) {
          this.$message({
            type: 'warning',
            message: '方案列表不能为空',
          })
        } else {
          console.log(this.form)
          let data = JSON.parse(JSON.stringify(this.form))
          delete data.time
          let sche_list = JSON.stringify(data.sche_list)
          data.sche_list = sche_list
          postAction(this.url.save, data).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.showedit = false
              this.$emit('getlist')
            }
          })
        }
      },
      handleAdd(row, index) {
        this.form.sche_list.push({
          total_amount: '',
          gift_amount: '',
          give_data: [],
          give_count: 2,
          goods_data: [],
          goods_count: 0,
          brand_data: [],
        })
      },
      xianding(goods, list, index) {
        console.log(goods, list, index)
        var b = []
        var a = []
        list.forEach((lit) => {
          let s = { brand_name: lit.brand_name, brand_id: lit.id }
          a.push(s)
        })
        this.form.sche_list[index].brand_data = a
        goods.forEach((item) => {
          item.goods_specs = item.specs
          item.unit_name = item.goods_unit
          item.goods_price = item.sell_price
          item.back_price = item.sell_price
          b.push(item)
        })
        this.form.sche_list[index].goods_data = b
      },
    },
  }
</script>

<style scoped></style>
