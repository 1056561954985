var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            center: "",
            "destroy-on-close": "",
            modal: false,
            visible: _vm.showedit,
            "close-on-click-modal": false,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showedit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "活动名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间:", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "time", $$v)
                          },
                          expression: "form.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动说明:", prop: "desc" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入活动说明",
                        },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "限定业务员:", prop: "limit_users" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("limit")(_vm.form.limit_users)) +
                          " "
                      ),
                      _c("add-employee", {
                        ref: "yee",
                        attrs: { imlit: _vm.form.limit_users },
                        on: { "set-userid": _vm.setUserid },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动方案:", prop: "sche_list" } },
                    [
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.form.sche_list } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "方案",
                              type: "index",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "预存金额",
                              align: "center",
                              prop: "total_amount",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.total_amount,
                                        callback: function ($$v) {
                                          _vm.$set(row, "total_amount", $$v)
                                        },
                                        expression: "row.total_amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "赠送金额",
                              align: "center",
                              prop: "gift_amount",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.gift_amount,
                                        callback: function ($$v) {
                                          _vm.$set(row, "gift_amount", $$v)
                                        },
                                        expression: "row.gift_amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "协议赠品", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          "text-decoration": "underline",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlergive(row, $index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.give_data.length) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.showedit
                                      ? _c("giveaway-ding", {
                                          attrs: {
                                            index: $index,
                                            "goods-list":
                                              _vm.form.sche_list[$index]
                                                .give_data,
                                          },
                                          on: { xieyi: _vm.xieyi },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "限定商品", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlergoods(row, $index)
                                          },
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(row.goods_data.length)),
                                        ]),
                                        _vm.showedit
                                          ? _c("limited", {
                                              ref: "limited",
                                              attrs: {
                                                "goods-list":
                                                  _vm.form.sche_list[$index]
                                                    .goods_data,
                                                "brand-list":
                                                  _vm.form.sche_list[$index]
                                                    .brand_data,
                                                index: $index,
                                              },
                                              on: { xianding: _vm.xianding },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdd(row, $index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 添加 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleradding(
                                              row,
                                              $index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 复制 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerrvm($index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text": "活动可重复参加",
                                },
                                model: {
                                  value: _vm.form.repeat_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "repeat_status", $$v)
                                  },
                                  expression: "form.repeat_status",
                                },
                              }),
                              _c("br"),
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text": "下单时业务员可修改价格",
                                },
                                model: {
                                  value: _vm.form.price_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "price_status", $$v)
                                  },
                                  expression: "form.price_status",
                                },
                              }),
                              _c("br"),
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text":
                                    "允许超额下单:勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额",
                                },
                                model: {
                                  value: _vm.form.exceed_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "exceed_status", $$v)
                                  },
                                  expression: "form.exceed_status",
                                },
                              }),
                              _c("br"),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "允许使用预存款购买常规赠品",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.gift_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "gift_status", $$v)
                                  },
                                  expression: "form.gift_status",
                                },
                              }),
                              _c("br"),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.form.check_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_status", $$v)
                                    },
                                    expression: "form.check_status",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 必须审核:所有预存款协议，必须后台审核后方可生效 "
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "0" },
                                  model: {
                                    value: _vm.form.check_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_status", $$v)
                                    },
                                    expression: "form.check_status",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 异常审核:勾选后，业务员修改协议内容，需后台审核后方可生效 "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赠送条件:" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("span"),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.gift_goods_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "gift_goods_type", $$v)
                                },
                                expression: "form.gift_goods_type",
                              },
                            },
                            _vm._l(_vm.citiesing, function (city) {
                              return _c(
                                "el-radio",
                                { key: city.id, attrs: { label: city.id } },
                                [_vm._v(" " + _vm._s(city.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showedit = !_vm.showedit
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlersubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }